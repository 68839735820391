import React, { useContext, useEffect, useState } from 'react';
import { CartContext } from '../context/cart.context';
import { useMutation } from 'react-query';
import { addWishList } from '../apiV2/wishlist';
import { AuthContext } from '../context/auth.context';
import { useNavigate } from 'react-router-dom';
import QuickView from './Quickview';
import $ from 'jquery';

export default function ProductItem({ product, large }: { product: any; large?: boolean }) {
  const { addItem, isItemInCart } = useContext(CartContext);
  const { user } = useContext(AuthContext);
  const isProductInCart = isItemInCart(product);
  const navigate = useNavigate();

  const wishListData = {
    productId: product?._id,
    userId: user?._id,
  };

  const { mutate: addProductToWishList } = useMutation(addWishList, {
    onSuccess: () => {
      alert(`Product: ${product?.name || ''} added to wishlist`);
    },
    onError: () => {
      alert(`Error adding Product: ${product.name || ''} to wishlist, please Login`);
      (window as any).$('#formLoginRegister').modal('show');
    },
  });

  const [showModal, setShowModal] = useState(false);

  const handleQuickViewClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleAddToCart = async () => {
    if (user) {
      if (isProductInCart) {
        alert('This product is already in your cart');
      } else {
        addItem(product);
        alert('Product added to cart');
      }
    } else {
      (window as any).$('#formLoginRegister').modal('show');
      alert('Please login first to add to cart');
    }
  };

  useEffect(() => {
    const productElement = $(`#product-${product?._id}`);
    const productImgElement = $(`#product-img-${product?._id}`);

    productElement.hover(
      function () {
        productImgElement.attr('src', product?.images?.[1] || product?.images?.[0]);
      },
      function () {
        productImgElement.attr('src', product?.images?.[0]);
      },
    );

    return () => {
      productElement.off('hover');
    };
  }, [product]);

  return (
    <div className={`col-xl-${large ? '4' : '3'} col-md-6`} style={{ cursor: 'pointer' }} id={`product-${product?._id}`}>
      <div className="product">
        {product.discount && (
          <div className="product-label">
            <span className="onsale">{product.discount || 0}%</span>
          </div>
        )}

        <div className="product-image">
          <div className="product-thumb-inner">
            <a href={`/shopSingle?productId=${product?.readableId}`}>
              <img className="img-fluid" id={`product-img-${product?._id}`} src={product.images[0]} alt="image" />
            </a>
          </div>
          <div className="custom-icon">
            <ul className="list-unstyled">
              <li>
                <a data-bs-toggle="tooltip" data-bs-placement="left" title="wishlist" onClick={() => addProductToWishList(wishListData)}>
                  <i className="far fa-heart"></i>
                </a>
              </li>
              <li>
                <a data-bs-toggle="tooltip" data-bs-placement="left" title="Quick View" onClick={handleQuickViewClick}>
                  <i className="fa-regular fa-eye"></i>
                </a>
                {showModal && <QuickView product={product} onClose={handleModalClose} />}
              </li>
            </ul>
          </div>
          <div className="product-btn">
            <button onClick={handleAddToCart} style={{ cursor: 'pointer', width: '100%' }} className="btn btn-light d-block">
              {isProductInCart ? 'In Cart' : 'Add To Cart'}
              <i className="fas fa-arrow-right-long ps-3"></i>
            </button>
          </div>
        </div>
        <div className="product-content">
          <div className="product-info">
            <div className="product-title">
              <h3>
                <a onClick={() => navigate(`/shopSingle?productId=${product?.readableId}`)}>{product.name || 'no product name'}</a>
              </h3>
            </div>
          </div>

          <div className="product-price">
            <p>
              <span className="me-2">
                <del>${product.oldPrice}</del>
              </span>
              <span className="text-primary">${product.price || 'No price'}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
